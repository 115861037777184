import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import store from "../../../redux/store";

import { Container, FE_VERSION, FOOTER_TEXT } from "../../../constants";
import { getTagDSTheme } from "../../../utilities/theme";

import "./footer.scss";

/**
 * Component that renders the app footer
 */
const Footer: React.FC<any> = (props): ReactElement => {
  const footerVersion = `${FE_VERSION}.25.2`;
  const { container } = props;
  return (
    <footer id="footer" className={`tag-ds small ${getTagDSTheme(store?.getState()?.tenant?.selectedTenant?.theme)}`} style={props.style}>
      {container === Container.internal ? (
        <Link to="/release-notes" className="link-version">{`Connect Version ${footerVersion} • ${FOOTER_TEXT}`}</Link>
      ) : (
        <>{`Connect Version ${footerVersion} • ${FOOTER_TEXT}`}</>
      )}
    </footer>
  );
};

export default Footer;
